import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function FeatureGallery() {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "featuredphotos" } }
        sort: { fields: base }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              fluid(quality: 90, maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="featurelayout">
      {data.allFile.edges.map(edge => (
        <div key={edge.node.id} className="featureImgContainer">
          <Img fluid={edge.node.childImageSharp.fluid} />
        </div>
      ))}
    </div>
  )
}
