import React from "react"
import Layout from "../components/layout"
import "../styles/forms.css"
import SEO from "../components/seo"

import FeatureGallery from "../components/featuregallery"

export default function Work() {
  return (
    <Layout title="Work">
      <SEO
        title="Creative Work We've Done"
        keyWords={[
          `Social Media Content Creator`,
          `Social Media Agency`,
          `Albuquerque`,
          `Albuquerque New Mexico`,
          `Product Photography`,
          `Rio Rancho, NM`,
          `Food Photography`,
          `Brand Content`,
          `ABQ Local`,
          `Comercial Photography`,
          `Soona Albuquerque`,
        ]}
      />
      <FeatureGallery />
    </Layout>
  )
}
